<template>
  <div class="storage-container">
      <svg :width="`${width}`" :height="`${height}`" viewBox="0 0 42 42" class="donut">
        <circle
          class="donut-hole"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="#fff"
        ></circle>
        <circle
          class="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="8"
        ></circle>
        <circle
          class="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#2176FF"
          stroke-width="8"
          :stroke-dasharray="`${percentage} ${percentage}`"
          stroke-dashoffset="30"
        ></circle>
        <circle
          class="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#D9D9D9"
          stroke-width="8"
          :stroke-dasharray="`${percentage} ${percentage}`"
          :stroke-dashoffset="30"
        ></circle>
    </svg>
  </div>
</template>

  <script>
  export default {
    name: "CircularProgressBar",
    props: {
      percentage: Number,
      width: {
        type: String,
        default: "36px"
      },
      height: {
        type: String,
        default: "36px"
    },
    },
    computed: {}
  };
</script>

<style scoped>
</style>